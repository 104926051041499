var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "resort-form" }, [
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c("div", { staticClass: "vx-col w-2/12" }, [
        _c(
          "label",
          { staticClass: "vs-input--label" },
          [
            _vm._v(" " + _vm._s(_vm.$t("shops.active")) + " "),
            _c("vs-switch", {
              staticClass: "mt-3",
              attrs: { type: "boolean" },
              model: {
                value: _vm.value.active,
                callback: function($$v) {
                  _vm.$set(_vm.value, "active", $$v)
                },
                expression: "value.active"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vx-col w-2/12" }, [
        _c(
          "label",
          { staticClass: "vs-input--label" },
          [
            _vm._v(" " + _vm._s(_vm.$t("shops.capacity.title")) + " "),
            _c("vs-switch", {
              staticClass: "mt-3",
              attrs: { type: "boolean" },
              model: {
                value: _vm.value.capacity,
                callback: function($$v) {
                  _vm.$set(_vm.value, "capacity", $$v)
                },
                expression: "value.capacity"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vx-col w-3/12" }, [
        _c(
          "label",
          { staticClass: "vs-input--label" },
          [
            _vm._v(" " + _vm._s(_vm.$t("shops.phone_required")) + " "),
            _c("vs-switch", {
              staticClass: "mt-3",
              attrs: { type: "boolean" },
              model: {
                value: _vm.value.phone_required,
                callback: function($$v) {
                  _vm.$set(_vm.value, "phone_required", $$v)
                },
                expression: "value.phone_required"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.name")))
          ]),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "w-full",
            attrs: {
              name: "name",
              danger: _vm.errorName && _vm.errors.has("name"),
              "danger-text": _vm.$t("shops.errors.name_is_required")
            },
            model: {
              value: _vm.value.name,
              callback: function($$v) {
                _vm.$set(_vm.value, "name", $$v)
              },
              expression: "value.name"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.address")))
          ]),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "w-full",
            attrs: {
              name: "address",
              danger: _vm.errorAddress && _vm.errors.has("address"),
              "danger-text": _vm.$t("shops.errors.address_is_required")
            },
            model: {
              value: _vm.value.address,
              callback: function($$v) {
                _vm.$set(_vm.value, "address", $$v)
              },
              expression: "value.address"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.currency")))
          ]),
          _c("v-select", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            attrs: {
              name: "currency",
              danger: _vm.errorCurrency && _vm.errors.has("currency"),
              "danger-text": _vm.$t("shops.errors.currency_is_required"),
              options: _vm.getCurrencyList(),
              label: "Currency"
            },
            model: {
              value: _vm.value.currency,
              callback: function($$v) {
                _vm.$set(_vm.value, "currency", $$v)
              },
              expression: "value.currency"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.email")))
          ]),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "w-full",
            attrs: {
              name: "email",
              danger: _vm.errorEmail && _vm.errors.has("email"),
              "danger-text": _vm.$t("shops.errors.email_is_required")
            },
            model: {
              value: _vm.value.email,
              callback: function($$v) {
                _vm.$set(_vm.value, "email", $$v)
              },
              expression: "value.email"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.phone")))
          ]),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "w-full",
            attrs: {
              name: "phone",
              danger: _vm.errorPhone && _vm.errors.has("phone"),
              "danger-text": _vm.$t("shops.errors.phone_is_required")
            },
            model: {
              value: _vm.value.phone,
              callback: function($$v) {
                _vm.$set(_vm.value, "phone", $$v)
              },
              expression: "value.phone"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.url")))
          ]),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            staticClass: "w-full",
            attrs: {
              name: "url",
              danger: _vm.errorURL && _vm.errors.has("url"),
              "danger-text": _vm.$t("shops.errors.URL_is_required")
            },
            model: {
              value: _vm.value.url,
              callback: function($$v) {
                _vm.$set(_vm.value, "url", $$v)
              },
              expression: "value.url"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c("div", { staticClass: "vx-col w-1/3" }, [
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-6/12" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(_vm._s(_vm.$t("shops.primary_color_css")))
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { name: "primary_color" },
                model: {
                  value: _vm.value.primary_color,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "primary_color", $$v)
                  },
                  expression: "value.primary_color"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-6/12" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(_vm._s(_vm.$t("shops.accent_color_css")))
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { name: "accent_color" },
                model: {
                  value: _vm.value.accent_color,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "accent_color", $$v)
                  },
                  expression: "value.accent_color"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.select_purchase_condition_page")))
          ]),
          _vm.pagesList.length > 0
            ? _c("v-select", {
                staticClass: "w-full select-auto",
                attrs: {
                  options: _vm.pagesList.map(function(page) {
                    return { label: page.name, value: page.id }
                  }),
                  value: _vm.value.pageConditions.id,
                  name: "page",
                  placeholder: _vm.$t("shops.select_a_page")
                },
                on: {
                  input: function(option) {
                    return _vm.selectPageList(option)
                  }
                },
                model: {
                  value: _vm.value.pageConditions.name,
                  callback: function($$v) {
                    _vm.$set(_vm.value.pageConditions, "name", $$v)
                  },
                  expression: "value.pageConditions.name"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.email_copy")))
          ]),
          _c("vs-input", {
            staticClass: "w-full",
            attrs: { name: "email_copy" },
            model: {
              value: _vm.value.email_copy,
              callback: function($$v) {
                _vm.$set(_vm.value, "email_copy", $$v)
              },
              expression: "value.email_copy"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c("div", { staticClass: "vx-col w-1/3" }, [
        _c("label", { staticClass: "vs-input--label mr-2" }, [
          _vm._v(_vm._s(_vm.$t("shops.logo")) + " (height: 120px)")
        ]),
        _vm.value.logo
          ? _c("img", {
              staticClass: "resort-logo",
              attrs: { src: _vm.value.logo }
            })
          : _vm._e(),
        _c("input", { attrs: { type: "file" }, on: { change: _vm.uploadLogo } })
      ]),
      _c("div", { staticClass: "vx-col mt:w-1/3" }, [
        _c("label", { staticClass: "vs-input--label mr-2" }, [
          _vm._v(_vm._s(_vm.$t("shops.resort_photo")) + " (800 x 600px)")
        ]),
        _vm.value.photo
          ? _c("img", {
              staticClass: "resort-img",
              attrs: { src: _vm.value.photo }
            })
          : _vm._e(),
        _c("input", {
          attrs: { type: "file" },
          on: { change: _vm.uploadPhoto }
        })
      ])
    ]),
    _c(
      "div",
      { staticClass: "vx-col w-1/3" },
      [
        _c(
          "vs-button",
          {
            staticClass: "mt-base",
            attrs: { type: "filled" },
            on: {
              click: function($event) {
                return _vm.validateAndSubmit(_vm.value)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("shops.save")))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }