<template>
  <div>
    <vx-card class="mb-base no-shadow">
      <template v-if="showForm">
        <vs-button @click="onCreate">{{ $t("Back") }}</vs-button>
      </template>
      <template v-if="!showForm">
        <div class="mb-base">
          <data-table
            class="datatableOrders"
            :data="tableData"
            :options="tableOptions"
            :exportRequest="exportRequest"
            @selected="onSelected($event, 'OrderInfo', 'order_id')"
            @sort="onSort"
            @search="onSearch"
            @change-page="onTablePageChange"
            @per-page-change="onPerPageChange"
            @date-range-search="onDateRangeSearch"
            @date-range-clear="onDateRangeClear"
          >
          </data-table>
        </div>
      </template>

      <div class="mb-base mt-10" v-if="showForm">
        <order-form v-model="formData" @submit="onOrderFormSubmit" />
      </div>
    </vx-card>
  </div>
</template>

<script>
import DataTable from "@/modules/Shared/Components/table/DataTable";
import OrderForm from "@/modules/Shops/Pages/forms/OrderForm.vue";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Orders",

  mixins: [notifications, table_functions],

  components: {
    DataTable,
    OrderForm,
  },

  data() {
    return {
      dialogCustomers: false,
      dialogPromotions: false,
      showForm: false,
      formData: {},
      defaultSort: "-id",
      showDialog: false,
      exportRequest: {},
      exportPayload: {}
    };
  },

  computed: {
    ...mapGetters("shops/orders", {
      ordersList: "getAll",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage"
    }),

    ...mapGetters("shops/statuses", {
      statusList: "getAll",
      statusById: "getById",
    }),

    tableData() {
      return this.ordersList.map(this.orderToTableData);
    },

    tableOptions() {
      return {
        actions: {
          create: false,
          update: false,
          delete: false,
          custom: true,
          select: true,
          info: true
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
        filters: {
          dateRange: true,
        },
      };
    },
  },

  methods: {
    ...mapActions("shops/orders", {
      loadOrder: "load"
    }),
    ...mapActions("shops/statuses", {
      loadStatus: "load"
    }),

    async loadData(params){
      if (this.statusList.length <= 0) await this.loadStatus()
      await this.loadOrder(params)
    },

    orderToTableData(order) {
      return {
        id: order.id,
        firstname: order.firstname,
        lastname: order.lastname,
        email: order.email,
        phone: order.phone,
        reference: order.reference,
        status: this.statusById(order.status_id).name,
        total_price_tax: order.total_price_tax,
        currency: order.currency,
        created_at: order.created_at,
        updated_at: order.updated_at,
      };
    },

    default() {
      return {
        id: 0,
        active: false,
        reference: "",
        person_id: 0,
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        currency: "EUR",
        total_products: 0,
        total_products_tax: 0,
        total_discounts: 0,
        total_discounts_tax: 0,
        total_shipping: 0,
        total_shipping_tax: 0,
        total_tax: 0,
        total_price: 0,
        total_price_tax: 0,
        promotion_name: "",
        confirmation_code: "",
      }
    }
  },

  created() {
    this.exportPayload.sort = this.defaultSort;

    this.exportRequest = {
      request: `resorts/${this.$route.params.uuid}/orders_export`,
      payload: this.exportPayload
    };
    this.$store.commit("shops/orders/RESET_RESOURCE");
  }
};
</script>

<style>
  .danger-border div,
  .danger-border-select div input {
    border-color: red !important;
  }
  .addDialog .vs-dialog {
    max-width: 1000px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 800px;
  }
  .datatableOrders th:nth-child(1) {
    display: none;
  }
  .datatableOrders td:nth-child(1) {
    display: none;
  }
</style>
