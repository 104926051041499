var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-form" },
    [
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Code")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "code",
                danger: _vm.errorCode && _vm.errors.has("code"),
                "danger-text": _vm.$t("shops.errors.code_is_required")
              },
              model: {
                value: _vm.value.code,
                callback: function($$v) {
                  _vm.$set(_vm.value, "code", $$v)
                },
                expression: "value.code"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Reference")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "reference",
                danger: _vm.errorReference && _vm.errors.has("reference"),
                "danger-text": _vm.$t("shops.errors.reference_is_required")
              },
              model: {
                value: _vm.value.reference,
                callback: function($$v) {
                  _vm.$set(_vm.value, "reference", $$v)
                },
                expression: "value.reference"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("First Name")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "firstName",
                danger: _vm.errorFirstName && _vm.errors.has("firstName"),
                "danger-text": _vm.$t("shops.errors.first_name_is_required")
              },
              model: {
                value: _vm.value.firstname,
                callback: function($$v) {
                  _vm.$set(_vm.value, "firstname", $$v)
                },
                expression: "value.firstname"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Last Name")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "lastName",
                danger: _vm.errorLastName && _vm.errors.has("lastName"),
                "danger-text": _vm.$t("shops.errors.last_name_is_required")
              },
              model: {
                value: _vm.value.lastname,
                callback: function($$v) {
                  _vm.$set(_vm.value, "lastname", $$v)
                },
                expression: "value.lastname"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Email")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "email",
                danger: _vm.errorEmail && _vm.errors.has("email"),
                "danger-text": _vm.$t("shops.errors.email_is_required")
              },
              model: {
                value: _vm.value.email,
                callback: function($$v) {
                  _vm.$set(_vm.value, "email", $$v)
                },
                expression: "value.email"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/8" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Total products")))
            ]),
            _c("vs-input-number", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: {
                type: "number",
                name: "totalProducts",
                danger:
                  _vm.errorTotalProducts && _vm.errors.has("totalProducts"),
                "danger-text": _vm.$t("shops.errors.total_products_is_required")
              },
              model: {
                value: _vm.value.total_products,
                callback: function($$v) {
                  _vm.$set(_vm.value, "total_products", $$v)
                },
                expression: "value.total_products"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/8" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Total products tax")))
            ]),
            _c("vs-input-number", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: {
                type: "number",
                name: "totalProductsTax",
                danger:
                  _vm.errorTotalProductsTax &&
                  _vm.errors.has("totalProductsTax"),
                "danger-text": _vm.$t(
                  "shops.errors.total_products_tax_is_required"
                )
              },
              model: {
                value: _vm.value.total_products_tax,
                callback: function($$v) {
                  _vm.$set(_vm.value, "total_products_tax", $$v)
                },
                expression: "value.total_products_tax"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/8" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Total discounts")))
            ]),
            _c("vs-input-number", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: {
                name: "totalDiscounts",
                danger:
                  _vm.errorTotalDiscounts && _vm.errors.has("totalDiscounts"),
                "danger-text": _vm.$t(
                  "shops.errors.total_discounts_is_required"
                )
              },
              model: {
                value: _vm.value.total_discounts,
                callback: function($$v) {
                  _vm.$set(_vm.value, "total_discounts", $$v)
                },
                expression: "value.total_discounts"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/8" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Total discount tax")))
            ]),
            _c("vs-input-number", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: {
                name: "totalDiscountTax",
                danger:
                  _vm.errorTotalDiscountsTax &&
                  _vm.errors.has("totalDiscountTax"),
                "danger-text": _vm.$t(
                  "shops.errors.total_discount_tax_is_required"
                )
              },
              model: {
                value: _vm.value.total_discounts_tax,
                callback: function($$v) {
                  _vm.$set(_vm.value, "total_discounts_tax", $$v)
                },
                expression: "value.total_discounts_tax"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/8" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Total shipping")))
            ]),
            _c("vs-input-number", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: {
                name: "totalShipping",
                danger:
                  _vm.errorTotalShipping && _vm.errors.has("totalShipping"),
                "danger-text": _vm.$t("shops.errors.total_shipping_is_required")
              },
              model: {
                value: _vm.value.total_shipping,
                callback: function($$v) {
                  _vm.$set(_vm.value, "total_shipping", $$v)
                },
                expression: "value.total_shipping"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/8" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Total shipping tax")))
            ]),
            _c("vs-input-number", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: {
                name: "totalShippingTax",
                danger:
                  _vm.errorTotalShippingTax &&
                  _vm.errors.has("totalShippingTax"),
                "danger-text": _vm.$t(
                  "shops.errors.total_shipping_tax_is_required"
                )
              },
              model: {
                value: _vm.value.total_shipping_tax,
                callback: function($$v) {
                  _vm.$set(_vm.value, "total_shipping_tax", $$v)
                },
                expression: "value.total_shipping_tax"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/8" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Total tax")))
            ]),
            _c("vs-input-number", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: {
                name: "totalTax",
                danger: _vm.errorTotalTax && _vm.errors.has("totalTax"),
                "danger-text": _vm.$t("shops.errors.total_tax_is_required")
              },
              model: {
                value: _vm.value.total_tax,
                callback: function($$v) {
                  _vm.$set(_vm.value, "total_tax", $$v)
                },
                expression: "value.total_tax"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/8" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Total price")))
            ]),
            _c("vs-input-number", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: {
                name: "totalPrice",
                danger: _vm.errorTotalPrice && _vm.errors.has("totalPrice"),
                "danger-text": _vm.$t("shops.errors.total_price_is_required")
              },
              model: {
                value: _vm.value.total_price,
                callback: function($$v) {
                  _vm.$set(_vm.value, "total_price", $$v)
                },
                expression: "value.total_price"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/8" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Total price tax")))
            ]),
            _c("vs-input-number", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: {
                name: "totalPriceTax",
                danger:
                  _vm.errorTotalPriceTax && _vm.errors.has("totalPriceTax"),
                "danger-text": _vm.$t(
                  "shops.errors.total_price_tax_is_required"
                )
              },
              model: {
                value: _vm.value.total_price_tax,
                callback: function($$v) {
                  _vm.$set(_vm.value, "total_price_tax", $$v)
                },
                expression: "value.total_price_tax"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Promotion name")))
            ]),
            _c("vs-input", {
              staticClass: "w-full",
              attrs: { name: "promotionName" },
              model: {
                value: _vm.value.promotion_name,
                callback: function($$v) {
                  _vm.$set(_vm.value, "promotion_name", $$v)
                },
                expression: "value.promotion_name"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Confirmation code")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "confirmationCode",
                danger:
                  _vm.errorConfirmationCode &&
                  _vm.errors.has("confirmationCode"),
                "danger-text": _vm.$t(
                  "shops.errors.confirmation_code_is_required"
                )
              },
              model: {
                value: _vm.value.confirmation_code,
                callback: function($$v) {
                  _vm.$set(_vm.value, "confirmation_code", $$v)
                },
                expression: "value.confirmation_code"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Status")))
            ]),
            _c("v-select", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full select-large",
              class: {
                "danger-border-select":
                  _vm.errorStatus && _vm.errors.has("status")
              },
              attrs: {
                options: _vm.status.map(function(status) {
                  return { label: status.name, value: status.id }
                }),
                value: _vm.value.status_id,
                name: "status",
                placeholder: _vm.$t("shops.select_a_status")
              },
              on: {
                input: function(status) {
                  return _vm.selectStatus(status)
                }
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/6" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Customer")))
            ]),
            _c("v-select", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full select-large",
              class: {
                "danger-border-select":
                  _vm.errorCustomer && _vm.errors.has("customer")
              },
              attrs: {
                options: _vm.customers.map(function(customer) {
                  return { label: customer.email, value: customer.id }
                }),
                value: _vm.value.customer_id,
                name: "customer",
                placeholder: _vm.$t("shops.select_a_customer"),
                "danger-text": _vm.$t("shops.errors.customer_is_required"),
                danger: _vm.errorCustomer && _vm.errors.has("customer")
              },
              on: {
                input: function(customer) {
                  return _vm.selectCustomer(customer)
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/6" },
          [
            _c(
              "vs-button",
              {
                staticClass: "w-full mt-6",
                attrs: { type: "border" },
                on: {
                  click: function($event) {
                    _vm.dialogCustomers = true
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("Add new customer")))]
            ),
            _c(
              "vs-prompt",
              {
                staticClass: "addDialog",
                attrs: {
                  "buttons-hidden": true,
                  title: "Add new customer",
                  active: _vm.dialogCustomers
                },
                on: {
                  "update:active": function($event) {
                    _vm.dialogCustomers = $event
                  }
                }
              },
              [_c("Customers", { attrs: { onlyForm: true } })],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/6" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Cart id")))
            ]),
            _c("v-select", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full select-large",
              class: {
                "danger-border-select": _vm.errorCart && _vm.errors.has("cart")
              },
              attrs: {
                options: _vm.carts.map(function(cart) {
                  return { label: cart.id, value: cart.id }
                }),
                value: _vm.value.cart_id,
                name: "cart",
                placeholder: _vm.$t("shops.select_a_cart"),
                "danger-text": _vm.$t("shops.errors.cart_is_required"),
                danger: _vm.errorCart && _vm.errors.has("cart")
              },
              on: {
                input: function(cart) {
                  return _vm.selectCart(cart)
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/6" },
          [
            _c(
              "vs-button",
              { staticClass: "w-full mt-6", attrs: { type: "border" } },
              [_vm._v(_vm._s(_vm.$t("Create new cart")))]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/6" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Promotion")))
            ]),
            _c("v-select", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full select-large",
              class: {
                "danger-border-select":
                  _vm.errorPromotion && _vm.errors.has("promotion")
              },
              attrs: {
                options: _vm.promotions.map(function(promo) {
                  return { label: promo.name, value: promo.id }
                }),
                value: _vm.value.promotion_id,
                name: "promotion",
                placeholder: _vm.$t("shops.select_a_promotion"),
                "danger-text": _vm.$t("shops.errors.promotion_is_required"),
                danger: _vm.errorPromotion && _vm.errors.has("promotion")
              },
              on: {
                input: function(promo) {
                  return _vm.selectPromotion(promo)
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/6" },
          [
            _c(
              "vs-button",
              {
                staticClass: "w-full mt-6",
                attrs: { type: "border" },
                on: {
                  click: function($event) {
                    _vm.dialogPromotions = true
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("Add new promotion")))]
            ),
            _c(
              "vs-prompt",
              {
                staticClass: "addDialog",
                attrs: {
                  "buttons-hidden": true,
                  title: "Add new promotion",
                  active: _vm.dialogPromotions
                },
                on: {
                  "update:active": function($event) {
                    _vm.dialogPromotions = $event
                  }
                }
              },
              [_c("Promotions", { attrs: { onlyForm: true } })],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/6" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Address")))
            ]),
            _c("v-select", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full select-large",
              class: {
                "danger-border-select":
                  _vm.errorAddress && _vm.errors.has("address")
              },
              attrs: {
                options: _vm.addresses.map(function(addr) {
                  return { label: addr.name, value: addr.id }
                }),
                value: _vm.value.address_id,
                name: "address",
                placeholder: _vm.$t("shops.select_a_address"),
                "danger-text": _vm.$t("shops.errors.address_is_required"),
                danger: _vm.errorAddress && _vm.errors.has("address")
              },
              on: {
                input: function(addr) {
                  return _vm.selectAddress(addr)
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/6" },
          [
            _c(
              "vs-button",
              { staticClass: "w-full mt-6", attrs: { type: "border" } },
              [_vm._v(_vm._s(_vm.$t("Add new address")))]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/6" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("Payment method")))
            ]),
            _c("v-select", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full select-large",
              class: {
                "danger-border-select":
                  _vm.errorPayment && _vm.errors.has("payment")
              },
              attrs: {
                options: _vm.payments.map(function(payment) {
                  return { label: payment.name, value: payment.id }
                }),
                value: _vm.value.payment_id,
                name: "payment",
                placeholder: _vm.$t("shops.select_a_payment_method"),
                "danger-text": _vm.$t(
                  "shops.errors.payment_method_is_required"
                ),
                danger: _vm.errorPayment && _vm.errors.has("payment")
              },
              on: {
                input: function(payment) {
                  return _vm.selectPayment(payment)
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/6" },
          [
            _c(
              "vs-button",
              { staticClass: "w-full mt-6", attrs: { type: "border" } },
              [_vm._v(_vm._s(_vm.$t("Add new payment method")))]
            )
          ],
          1
        )
      ]),
      _c("vs-divider"),
      _c(
        "vs-button",
        {
          attrs: { type: "filled" },
          on: {
            click: function($event) {
              return _vm.$emit("submit", _vm.value)
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("shops.save")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }