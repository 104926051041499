var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-base mt-10 no-shadow" },
        [
          _vm.loadedForm
            ? _c("resort-form", {
                on: { submit: _vm.onResortFormSubmit },
                model: {
                  value: _vm.formData,
                  callback: function($$v) {
                    _vm.formData = $$v
                  },
                  expression: "formData"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }