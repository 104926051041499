<template>
  <div>
    <vx-card class="mb-base mt-10 no-shadow">
      <resort-form v-if="loadedForm" v-model="formData" @submit="onResortFormSubmit" />
    </vx-card>
  </div>
</template>

<script>
import ResortForm from "@/modules/Shops/Pages/forms/ResortForm.vue";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import { mapGetters, mapActions } from "vuex";
import i18n from "@/i18n";

const defaultResort = () => ({
  id: 0,
  name: 0,
  logo: "",
  address: "",
  email: "",
  phone: "",
  url: "",
  currency: "",
  color: "",
  primary_color: "",
  accent_color: "",
  pageConditions: {},
  conditions_page_id: null,
});

export default {
  name: "Resort",

  mixins: [notifications],

  components: {
    ResortForm,
  },

  data() {
    return {
      formData: defaultResort(),
      loadedForm: false,
    };
  },

  computed: {
    ...mapGetters("shops/resorts", {
      resortById: "getById",
    }),
    ...mapGetters("auth", {
      drm: "drm",
    }),
  },

  methods: {
    ...mapActions("shops/resorts", ["load"]),
    async onResortFormSubmit(resort) {
      try {
        await this.update(resort);
      } catch (error) {
        this.notifyError(i18n.t("shops.failed_to_save_resort"), error);
      }
    },

    async update(resort) {
      try {
        await this.$store.dispatch("shops/resorts/update", resort);
      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_update_resort"), error);
      }
      await this.load({ id: this.$route.params.uuid }); // Carregar el resort al que estàs un altre cop. this.load({id: this.$route.params.uuid})
      this.formData = this.resortById(Number(this.$route.params.uuid));
      this.notifySuccess(
        i18n.t("shops.update"),
        `${i18n.t('shops.resort')} ${resort.name} ${i18n.t('shops.has_been_updated_successfully')}`
      );
    },
  },

  async created() {
    this.$vs.loading();
    try{
      this.load({ id: this.$route.params.uuid }).then(() => {
        this.formData = this.resortById(Number(this.$route.params.uuid));
        this.loadedForm = !this.loadedForm;
        this.$vs.loading.close();
      });
    }
    catch(e){
      console.warn(e);
    }
    finally{
      this.$vs.loading.close();
    }
  },
};
</script>

<style>
</style>
