<template>
  <div class="order-form">
    <div class="vx-row mb-6">
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Code") }}</label>
        <vs-input
          class="w-full"
          v-model="value.code"
          v-validate="'required'"
          name="code"
          :danger="errorCode && errors.has('code')"
          :danger-text="$t('shops.errors.code_is_required')"
        />
      </div>
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Reference") }}</label>
        <vs-input
          class="w-full"
          v-model="value.reference"
          name="reference"
          v-validate="'required'"
          :danger="errorReference && errors.has('reference')"
          :danger-text="$t('shops.errors.reference_is_required')"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("First Name") }}</label>
        <vs-input
          class="w-full"
          v-model="value.firstname"
          name="firstName"
          v-validate="'required'"
          :danger="errorFirstName && errors.has('firstName')"
          :danger-text="$t('shops.errors.first_name_is_required')"
        />
      </div>
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Last Name") }}</label>
        <vs-input
          class="w-full"
          v-model="value.lastname"
          name="lastName"
          v-validate="'required'"
          :danger="errorLastName && errors.has('lastName')"
          :danger-text="$t('shops.errors.last_name_is_required')"
        />
      </div>
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Email") }}</label>
        <vs-input
          class="w-full"
          v-model="value.email"
          name="email"
          v-validate="'required'"
          :danger="errorEmail && errors.has('email')"
          :danger-text="$t('shops.errors.email_is_required')"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-1/8">
        <label class="vs-input--label">{{ $t("Total products") }}</label>
        <vs-input-number
          type="number"
          v-model="value.total_products"
          name="totalProducts"
          v-validate="'required'"
          :danger="errorTotalProducts && errors.has('totalProducts')"
          :danger-text="$t('shops.errors.total_products_is_required')"
        />
      </div>
      <div class="vx-col w-1/8">
        <label class="vs-input--label">{{ $t("Total products tax") }}</label>
        <vs-input-number
          type="number"
          v-model="value.total_products_tax"
          name="totalProductsTax"
          v-validate="'required'"
          :danger="errorTotalProductsTax && errors.has('totalProductsTax')"
          :danger-text="$t('shops.errors.total_products_tax_is_required')"
        />
      </div>
      <div class="vx-col w-1/8">
        <label class="vs-input--label">{{ $t("Total discounts") }}</label>
        <vs-input-number
          v-model="value.total_discounts"
          name="totalDiscounts"
          v-validate="'required'"
          :danger="errorTotalDiscounts && errors.has('totalDiscounts')"
          :danger-text="$t('shops.errors.total_discounts_is_required')"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-1/8">
        <label class="vs-input--label">{{ $t("Total discount tax") }}</label>
        <vs-input-number
          v-model="value.total_discounts_tax"
          name="totalDiscountTax"
          v-validate="'required'"
          :danger="errorTotalDiscountsTax && errors.has('totalDiscountTax')"
          :danger-text="$t('shops.errors.total_discount_tax_is_required')"
        />
      </div>
      <div class="vx-col w-1/8">
        <label class="vs-input--label">{{ $t("Total shipping") }}</label>
        <vs-input-number
          v-model="value.total_shipping"
          name="totalShipping"
          v-validate="'required'"
          :danger="errorTotalShipping && errors.has('totalShipping')"
          :danger-text="$t('shops.errors.total_shipping_is_required')"
        />
      </div>
      <div class="vx-col w-1/8">
        <label class="vs-input--label">{{ $t("Total shipping tax") }}</label>
        <vs-input-number
          v-model="value.total_shipping_tax"
          name="totalShippingTax"
          v-validate="'required'"
          :danger="errorTotalShippingTax && errors.has('totalShippingTax')"
          :danger-text="$t('shops.errors.total_shipping_tax_is_required')"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-1/8">
        <label class="vs-input--label">{{ $t("Total tax") }}</label>
        <vs-input-number
          v-model="value.total_tax"
          name="totalTax"
          v-validate="'required'"
          :danger="errorTotalTax && errors.has('totalTax')"
          :danger-text="$t('shops.errors.total_tax_is_required')"
        />
      </div>
      <div class="vx-col w-1/8">
        <label class="vs-input--label">{{ $t("Total price") }}</label>
        <vs-input-number
          v-model="value.total_price"
          name="totalPrice"
          v-validate="'required'"
          :danger="errorTotalPrice && errors.has('totalPrice')"
          :danger-text="$t('shops.errors.total_price_is_required')"
        />
      </div>
      <div class="vx-col w-1/8">
        <label class="vs-input--label">{{ $t("Total price tax") }}</label>
        <vs-input-number
          v-model="value.total_price_tax"
          name="totalPriceTax"
          v-validate="'required'"
          :danger="errorTotalPriceTax && errors.has('totalPriceTax')"
          :danger-text="$t('shops.errors.total_price_tax_is_required')"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Promotion name") }}</label>
        <vs-input
          class="w-full"
          v-model="value.promotion_name"
          name="promotionName"
        />
      </div>
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Confirmation code") }}</label>
        <vs-input
          class="w-full"
          v-model="value.confirmation_code"
          name="confirmationCode"
          v-validate="'required'"
          :danger="errorConfirmationCode && errors.has('confirmationCode')"
          :danger-text="$t('shops.errors.confirmation_code_is_required')"
        />
      </div>
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("Status") }}</label>
        <v-select
          :options="status.map((status) => ({ label: status.name, value: status.id }))"
          @input="(status) => selectStatus(status)"
          :value="value.status_id"
          class="w-full select-large"
          :class="{
            'danger-border-select': errorStatus && errors.has('status')
          }"
          v-validate="'required'"
          name="status"
          :placeholder="$t('shops.select_a_status')"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-1/6">
        <label class="vs-input--label">{{ $t("Customer") }}</label>
        <v-select
          :options="customers.map((customer) => ({ label: customer.email, value: customer.id }))"
          @input="(customer) => selectCustomer(customer)"
          :value="value.customer_id"
          class="w-full select-large"
          :class="{
            'danger-border-select': errorCustomer && errors.has('customer')
          }"
          v-validate="'required'"
          name="customer"
          :placeholder="$t('shops.select_a_customer')"
          :danger-text="$t('shops.errors.customer_is_required')"
          :danger="errorCustomer && errors.has('customer')"
        />
      </div>
      <div class="vx-col w-1/6">
        <vs-button
          @click="dialogCustomers = true"
          class="w-full mt-6"
          type="border"
          >{{ $t("Add new customer") }}</vs-button
        >
        <vs-prompt
          :buttons-hidden="true"
          title="Add new customer"
          class="addDialog"
          :active.sync="dialogCustomers"
        >
          <Customers :onlyForm="true" />
        </vs-prompt>
      </div>
      <div class="vx-col w-1/6">
        <label class="vs-input--label">{{ $t("Cart id") }}</label>
        <v-select
          :options="carts.map((cart) => ({ label: cart.id, value: cart.id }))"
          @input="(cart) => selectCart(cart)"
          :value="value.cart_id"
          class="w-full select-large"
          :class="{
            'danger-border-select': errorCart && errors.has('cart')
          }"
          v-validate="'required'"
          name="cart"
          :placeholder="$t('shops.select_a_cart')"
          :danger-text="$t('shops.errors.cart_is_required')"
          :danger="errorCart && errors.has('cart')"
        />
      </div>
      <div class="vx-col w-1/6">
        <vs-button class="w-full mt-6" type="border">{{
          $t("Create new cart")
        }}</vs-button>
      </div>
      <div class="vx-col w-1/6">
        <label class="vs-input--label">{{ $t("Promotion") }}</label>
        <v-select
          :options="promotions.map((promo) => ({ label: promo.name, value: promo.id }))"
          @input="(promo) => selectPromotion(promo)"
          :value="value.promotion_id"
          class="w-full select-large"
          :class="{
            'danger-border-select': errorPromotion && errors.has('promotion')
          }"
          v-validate="'required'"
          name="promotion"
          :placeholder="$t('shops.select_a_promotion')"
          :danger-text="$t('shops.errors.promotion_is_required')"
          :danger="errorPromotion && errors.has('promotion')"
        />
      </div>
      <div class="vx-col w-1/6">
        <vs-button
          @click="dialogPromotions = true"
          class="w-full mt-6"
          type="border"
          >{{ $t("Add new promotion") }}</vs-button
        >
        <vs-prompt
          :buttons-hidden="true"
          title="Add new promotion"
          class="addDialog"
          :active.sync="dialogPromotions"
        >
          <Promotions :onlyForm="true" />
        </vs-prompt>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-1/6">
        <label class="vs-input--label">{{ $t("Address") }}</label>
        <v-select
          :options="addresses.map((addr) => ({ label: addr.name, value: addr.id }))"
          @input="(addr) => selectAddress(addr)"
          :value="value.address_id"
          class="w-full select-large"
          :class="{
            'danger-border-select': errorAddress && errors.has('address')
          }"
          v-validate="'required'"
          name="address"
          :placeholder="$t('shops.select_a_address')"
          :danger-text="$t('shops.errors.address_is_required')"
          :danger="errorAddress && errors.has('address')"
        />
      </div>
      <div class="vx-col w-1/6">
        <vs-button class="w-full mt-6" type="border">{{
          $t("Add new address")
        }}</vs-button>
      </div>
      <div class="vx-col w-1/6">
        <label class="vs-input--label">{{ $t("Payment method") }}</label>
        <v-select
          :options="payments.map((payment) => ({ label: payment.name, value: payment.id }))"
          @input="(payment) => selectPayment(payment)"
          :value="value.payment_id"
          class="w-full select-large"
          :class="{
            'danger-border-select': errorPayment && errors.has('payment')
          }"
          v-validate="'required'"
          name="payment"
          :placeholder="$t('shops.select_a_payment_method')"
          :danger-text="$t('shops.errors.payment_method_is_required')"
          :danger="errorPayment && errors.has('payment')"
        />
      </div>
      <div class="vx-col w-1/6">
        <vs-button class="w-full mt-6" type="border">{{
          $t("Add new payment method")
        }}</vs-button>
      </div>
    </div>
    <vs-divider />
    <vs-button type="filled" @click="$emit('submit', value)">{{
      $t("shops.save")
    }}</vs-button>
  </div>
</template>

<script>
import Customers from "@/modules/Shops/Pages/data/Customers.vue";
import Promotions from "@/modules/Shops/Pages/data/Promotions.vue";
import { mapGetters } from "vuex";

const defaultOrder = {
  id: 0,
  active: false,
  reference: "",
  firstname: "",
  lastname: "",
  email: "",
  currency: "EUR",
  total_products: 0,
  total_products_tax: 0,
  total_discounts: 0,
  total_discounts_tax: 0,
  total_shipping: 0,
  total_shipping_tax: 0,
  total_tax: 0,
  total_price: 0,
  total_price_tax: 0,
  promotion_name: "",
  confirmation_code: ""
};

export default {
  name: "OrderForm",
  components: {
    Customers,
    Promotions
  },
  props: {
    value: { type: Object, default: () => defaultOrder },
    dialogCustomers: { type: Boolean, default: false },
    dialogPromotions: { type: Boolean, default: false }
  },
  data() {
    return {
      errorCode: false,
      errorReference: false,
      errorFirstName: false,
      errorLastName: false,
      errorEmail: false,
      errorTotalProducts: false,
      errorTotalProductsTax: false,
      errorTotalDiscounts: false,
      errorTotalDiscountsTax: false,
      errorTotalShipping: false,
      errorTotalShippingTax: false,
      errorTotalTax: false,
      errorTotalPrice: false,
      errorTotalPriceTax: false,
      errorConfirmationCode: false,
      errorStatus: false,
      errorCustomer: false,
      errorCart: false,
      errorPromotion: false,
      errorAddress: false,
      errorPayment: false
    };
  },

  computed: {
    ...mapGetters("shops/statuses", {
      status: "getAll"
    }),
    ...mapGetters("shops/customers", {
      customers: "getAll"
    }),
    ...mapGetters("shops/carts", {
      carts: "getAll"
    }),
    ...mapGetters("shops/promotions", {
      promotions: "getAll"
    }),
    ...mapGetters("shops/addresses", {
      addresses: "getAll"
    }),
    ...mapGetters("shops/payments", {
      payments: "getAll"
    })
  },

  methods: {

    selectStatus(option) {
      this.value.status_id = option.value
    },

    selectCustomer(option) {
      this.value.customer_id = option.value;
    },

    selectCart(option) {
      this.value.cart_id = option.value;
    },

    selectPromotion(option) {
      this.value.promotion_id = option.value
    },

    selectAddress(option) {
      this.value.address_id = option.value;
    },

    selectPayment(option) {
      this.value.payment_id = option.value;
    }

  }
};
</script>

<style></style>
