<template>
  <div class="resort-form">
    <div class="vx-row mb-6">
      <div class="vx-col w-2/12">
        <label class="vs-input--label">
          {{ $t("shops.active") }}
          <vs-switch class="mt-3" type="boolean" v-model="value.active" />
        </label>
      </div>
      <div class="vx-col w-2/12">
        <label class="vs-input--label">
          {{ $t("shops.capacity.title") }}
          <vs-switch class="mt-3" type="boolean" v-model="value.capacity" />
        </label>
      </div>
      <div class="vx-col w-3/12">
        <label class="vs-input--label">
          {{ $t("shops.phone_required") }}
          <vs-switch class="mt-3" type="boolean" v-model="value.phone_required" />
        </label>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/3">
        <label class="vs-input--label">{{ $t("shops.name") }}</label>
        <vs-input
          class="w-full"
          v-model="value.name"
          v-validate="'required'"
          name="name"
          :danger="errorName && errors.has('name')"
          :danger-text="$t('shops.errors.name_is_required')"
        />
      </div>

      <div class="vx-col w-1/3">
        <label class="vs-input--label">{{ $t("shops.address") }}</label>
        <vs-input
          class="w-full"
          v-model="value.address"
          v-validate="'required'"
          name="address"
          :danger="errorAddress && errors.has('address')"
          :danger-text="$t('shops.errors.address_is_required')"
        />
      </div>

      <div class="vx-col w-1/3">
        <label class="vs-input--label">{{ $t("shops.currency") }}</label>
        <v-select
          v-validate="'required'"
          name="currency"
          v-model="value.currency"
          :danger="errorCurrency && errors.has('currency')"
          :danger-text="$t('shops.errors.currency_is_required')"
          :options="getCurrencyList()"
          label="Currency"
        ></v-select>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/3">
        <label class="vs-input--label">{{ $t("shops.email") }}</label>
        <vs-input
          class="w-full"
          v-model="value.email"
          v-validate="'required'"
          name="email"
          :danger="errorEmail && errors.has('email')"
          :danger-text="$t('shops.errors.email_is_required')"
        />
      </div>

      <div class="vx-col w-1/3">
        <label class="vs-input--label">{{ $t("shops.phone") }}</label>
        <vs-input
          class="w-full"
          v-model="value.phone"
          v-validate="'required'"
          name="phone"
          :danger="errorPhone && errors.has('phone')"
          :danger-text="$t('shops.errors.phone_is_required')"
        />
      </div>

      <div class="vx-col w-1/3">
        <label class="vs-input--label">{{ $t("shops.url") }}</label>
        <vs-input
          class="w-full"
          v-model="value.url"
          v-validate="'required'"
          name="url"
          :danger="errorURL && errors.has('url')"
          :danger-text="$t('shops.errors.URL_is_required')"
        />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/3">
        <div class="vx-row">
          <div class="vx-col w-6/12">
            <label class="vs-input--label">{{ $t("shops.primary_color_css")}}</label>
            <vs-input class="w-full" v-model="value.primary_color" name="primary_color" />
          </div>
          <div class="vx-col w-6/12">
            <label class="vs-input--label">{{ $t("shops.accent_color_css")}}</label>
            <vs-input class="w-full" v-model="value.accent_color" name="accent_color" />
          </div>
        </div>
      </div>

      <div class="vx-col w-1/3">
        <label class="vs-input--label">{{ $t("shops.select_purchase_condition_page") }}</label>
        <v-select
          v-if="pagesList.length > 0"
          v-model="value.pageConditions.name"
          :options="pagesList.map((page) => ({ label: page.name, value: page.id }))"
          @input="(option) => selectPageList(option)"
          class="w-full select-auto"
          :value="value.pageConditions.id"
          name="page"
          :placeholder="$t('shops.select_a_page')"
        />
      </div>

      <div class="vx-col w-1/3">
        <label class="vs-input--label">{{ $t("shops.email_copy")}}</label>
        <vs-input class="w-full" v-model="value.email_copy" name="email_copy" />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/3">
        <label class="vs-input--label mr-2">{{ $t("shops.logo") }} (height: 120px)</label>
        <img class="resort-logo" v-if="value.logo" v-bind:src="value.logo" />
        <input type="file" @change="uploadLogo" />
      </div>

      <div class="vx-col mt:w-1/3">
        <label class="vs-input--label mr-2">{{ $t("shops.resort_photo") }} (800 x 600px)</label>
        <img class="resort-img" v-if="value.photo" v-bind:src="value.photo" />
        <input type="file" @change="uploadPhoto" />
      </div>
    </div>

    <div class="vx-col w-1/3">
      <vs-button @click="validateAndSubmit(value)" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
    </div>
  </div>
</template>

<script>
import notifications from "@/modules/Shared/Mixins/notifications.js";
import { mapGetters, mapActions } from "vuex";
import i18n from "@/i18n";

const defaultResort = {
  active: false,
  id: 0,
  name: "",
  logo: "",
  photo: "",
  address: "",
  email: "",
  phone: "",
  url: "",
  currency: "",
  primary_color: "",
  accent_color: "",
  pageConditions: {},
  conditions_page_id: null,
  capacity: false,
  phone_required: false,
  email_copy: ""
};

const defaultCurrency = ["EUR", "USD", "NOK", "PLN"];

let loadedImages = {
  logo: null,
  photo: null
}

export default {
  name: "ResortForm",
  props: {
    value: { type: Object, default: () => defaultResort },
  },
  mixins: [notifications],
  data() {
    return {
      errorName: false,
      errorLogo: false,
      errorAddress: false,
      errorEmail: false,
      errorPhone: false,
      errorURL: false,
      errorCurrency: false,
      photoLoaded: "",
      logoLoaded: "",
    };
  },

  computed: {
    ...mapGetters("shops/pages", {
      pagesList: "getAll",
    }),
  },

  methods: {
    ...mapActions("shops/pages", { loadPages: "load" }),
    validateAndSubmit(value) {
      defaultResort.logo = loadedImages.logo ? loadedImages.logo : value.logo;
      defaultResort.photo = loadedImages.photo ? loadedImages.photo : value.photo;
      defaultResort.id = value.id;
      defaultResort.active = value.active;
      defaultResort.capacity = value.capacity;
      defaultResort.phone_required = value.phone_required;
      defaultResort.name = value.name;
      defaultResort.address = value.address;
      defaultResort.email = value.email;
      defaultResort.phone = value.phone;
      defaultResort.url = value.url;
      defaultResort.currency = value.currency;
      defaultResort.primary_color = value.primary_color;
      defaultResort.accent_color = value.accent_color;
      defaultResort.conditions_page_id = value.conditions_page_id;
      defaultResort.email_copy = value.email_copy;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("submit", defaultResort);
        } else {
          this.notifyWarning(i18n.t("shops.errors.warning"), i18n.t("shops.errors.resort_validation_failed"));
          if (!this.value.name) this.errorName = true;
          if (!this.value.logo) this.errorLogo = true;
          if (!this.value.address) this.errorAddress = true;
          if (!this.value.email) this.errorEmail = true;
          if (!this.value.phone) this.errorPhone = true;
          if (!this.value.url) this.errorURL = true;
          if (!this.value.currency) this.errorCurrency = true;
        }
      });
    },
    selectPageList(option) {
      this.value.conditions_page_id = option === null ? option : option.value
    },
    getCurrencyList() {
      if (process.env.VUE_APP_CURRENCY) {
        const currency = process.env.VUE_APP_CURRENCY;
        let money = currency.split(",");
        return money;
      } else {
        return defaultCurrency;
      }
    },
    async uploadLogo(e) {
      const image = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = function () {
        loadedImages.logo = reader.result.replace(/^data:image\/[a-z]+;base64/, "")
      };
    },

    async uploadPhoto(e) {
      const image = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = function () {
        loadedImages.photo = reader.result.replace(/^data:image\/[a-z]+;base64/, "")
      };
    },
  },

  async created() {
    await this.loadPages()
    if (this.value.pageConditions == null) this.value.pageConditions = {};
    loadedImages.logo = null
    loadedImages.photo = null
  },
};
</script>

<style lang="scss">
.resort-img {
  max-width: 500px;
  @media screen and (max-width: 612px) {
    width: 200px;
  }
}

.resort-logo {
  max-width: 300px;
}
</style>
